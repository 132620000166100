import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { TenantService } from "../services/tenant/tenant.service";
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantInterceptor implements HttpInterceptor {

  constructor(private tenantService: TenantService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.passenger.api.configServerUri)) {
      request = request.clone({
        headers: this.tenantService.addTenantHeader(request)
      });
    }
    return next.handle(request);
  }
}
