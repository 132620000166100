import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ScriptsUtilsService } from '@app/core/utils/scripts-utils.service';
import { Data } from '@app/core/models/data';
import { OgoneResponse } from '@app/core/models/financial-engine/ogoneResponse';

declare let Tokenizer: any;
@Component({
  selector: 'pp-ogone',
  templateUrl: './ogone.component.html',
  styleUrls: ['./ogone.component.css']
})
export class OgoneComponent implements OnInit {

  @Input() ogoneResponse: OgoneResponse;
  @Output() receive = new EventEmitter<any>();

  tokenizer: any;
  buttonDisabled: boolean = true;

  constructor(private scriptService: ScriptsUtilsService) {
  }

  ngOnInit() {
    this.scriptService.load('tokenizer').then(() => {
      this.tokenizer = new Tokenizer(
        `https://payment.${this.ogoneResponse.partialRedirectUrl}`,
        'div-hosted-tokenization',
        {
            hideCardholderName: false,
            hideTokenFields: true
        });
      this.tokenizer.initialize().catch((reason) => {
        const data : Data = {
          success : false,
          cancel : false
        }
       this.receive.emit(data);
      })
    })
  }

  submitForm() {
    this.tokenizer.submitTokenization().then((data) => {
        this.receive.emit({data});
    }).catch(() => {
      const data : Data = {
        success : false,
        cancel : false
      }
     this.receive.emit({data});
    }
    )
  }


  cancelForm(){
    const data : Data = {
      success : false,
      cancel : true
     }
    this.receive.emit({data});
  }

}
