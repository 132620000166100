import { commonEnvironment } from './environment.common';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  common: commonEnvironment,
  production: false,

  passenger: {
    /****************************************
    * Mutitenant activation
    ****************************************/
    multitenant: {
      enabled: false,
      tenant: '',
      useLocaly: true
    },
    /****************************************
    * API
    ****************************************/
    api: {
      gateway: '',
      mobilityEngine: '',
      financialEngine: '',
      sipsNormalReturnEnrollment: '',
      sipsNormalReturnAuthorization: '',
      configServerUri: '/api/tenant/configuration'
    },

    /****************************************
     * KEYCLOAK
     ****************************************/
    keycloak: {
      url: '',
      realm: '',
      clientId: ''
    },

    /****************************************
     * APPLICATION
     *   Define main application parameters (favicon, title...)
     ****************************************/
    application: {
      title: 'Tap2Use',
      favicon: './assets/img/brand/favicon0.png',
      languages: [],
      pageSize: 30, // Default table size

      // Footer configuration (legal aspects)
      footer: {
        cgu: {
          enabled: true,
          urlPath: 'cgu',
          targetAsset: '/assets/config/CGUPage/'
        },
        faq: {
          enabled: true,
          urlPath: 'faq',
          targetAsset: '/assets/config/FAQPage/'
        },
        legalNotice: {
          enabled: true,
          urlPath: 'legal-notice',
          targetAsset: '/assets/config/MentionLegalPage/'
        }
      }
    },

    /****************************************
     * COMPANIES
     ****************************************/
    companies: {
    },

    /****************************************
     * CUSTOMER REQUEST (SAV)
     ****************************************/
    customerRequest: {
      enabled: true,
      mode: 'internal', // internal vs external
      externalUrl: 'https://mont2uassistance.fr',
      availableSubjects: []
    },

    /****************************************
     * Journeys (activities)
     ****************************************/
    journeys: {
      columns: [],
      detailed: true,
      allowedTypes: [],
      duplicatePRDelay: 'PT20S',
      startTime: '',  // start time journey for the new module journey manager
      endTime: '', // start time journey for the new module journey manager
    },

    /****************************************
     * Settlement
     ****************************************/
    settlements: {
      receipt: {
        currency: 'EUR',
      },
      debtRecovery: {
        enabled: true // Display debt button to regularize your debt with sips
      }
    },

    /****************************************
     * SIPS
     ****************************************/
    sips: {
      defaultLanguage: 'fr',
      templates: {
        token: 'tap2use-non-payment', // to convert PAN to SIPS token
        payment: 'tap2use-payment' // to do a clearing
      }
    },
    ogone: {
      templateName: "HTPTemplate11_Full.html"
    }
  },

  /****************************************
   * CHOOSEN merchantMode
   ****************************************/
  merchantMode: 'sips', // at this time can be 'ogone' or 'sips',
  consumptionStatement: {
    enabled: false
  },
  ogone: {
    url: 'https://payment.preprod.direct.worldline-solutions.com'
  }
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
//-portal&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fpayments&state=0569df5e-7437-4b60-b241-dd520351ce7f&response_mode=fragment&response_type=code&scope=openid&nonce=7979b770-7484-402d-bec2-60a393b99c79
