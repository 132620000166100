import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccessMedia } from '@app/core/models/models';
import { AbstractOnDestroyComponent } from '@app/core/abstract-on-destroy-component/abstract-on-destroy-component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UtilService } from '@app/core/services/util.service';
import { Router } from "@angular/router";


@Component({
  selector: 'pp-access-media-filter',
  templateUrl: './access-media-filter.component.html',
  styleUrls: ['./access-media-filter.component.css']
})
export class AccessMediaFilterComponent extends AbstractOnDestroyComponent implements OnInit {
  @Input() filter: any;
  @Input() filterWithLocalDate: boolean = false;
  @Output() filterEvent = new EventEmitter<any>();

  private selectedAccessMedias: AccessMedia[];
  months;
  lastMonths = this.getLastMonths();
  disableButton = true;
  disableResetButton = true;
  showTitle = false;
  showDate = false;
  showPeriod = false;
  showMonth = false;
  filterType = 'date';

  get accessMedias(): any[] {
    return this.filter.accessMedias;
  }

  constructor(private translateService: TranslateService, private utilService: UtilService, private route: Router) {
    super();
  }

  ngOnInit() {
    this.filter.period.beginDate = null;
    this.filter.period.endDate = null;
    this.filter.date = null;
    this.selectedAccessMedias = this.filter.accessMedias;
    this.months = this.utilService.getMonthsList();

    this.translateService.onLangChange.subscribe((_d: LangChangeEvent) => {
      this.lastMonths = this.getLastMonths();
      this.months = this.utilService.getMonthsList();
    })
    this.showDate = true;

    if (this.route.url ==='/activities') {
      this.showTitle = true;
    }

  }

  getLastMonths() {
    let months = []
    let actualDate = new Date();

    for(let i = 0; i < 12; i++) {
      months.push(actualDate.toLocaleString(this.translateService.getDefaultLang(), { month: 'long' }) + ' ' + actualDate.getFullYear());
      actualDate.setMonth(actualDate.getMonth() - 1);
    }
    return months;
  }

  onCleanFilter() {
    this.filter.month = '';
    this.filter.date = null;
    this.filter.period.beginDate = null;
    this.filter.period.endDate = null;
    this.disableButton = true;
    this.disableResetButton = true;
    this.loadDatas({});
  }

  changePeriod() {
    this.disableButton = !(this.filter.period.beginDate && this.filter.period.endDate);
    this.disableResetButton = false;
  }

  changeMonth() {
    this.disableButton = this.filter.month === '';
    this.disableResetButton = false;
  }

  changeDate() {
    this.disableButton = !this.filter.date;
    this.disableResetButton = false;
  }

  lastDayOfTheMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  onSubmitFilterForm() {
    const period = this.getFiltredPeriode(this.filter);
    this.loadDatas(period);
  }

  loadDatas (period) {
    this.filterEvent.emit({ accessMedias: this.selectedAccessMedias, period });
  }
  displayDate() {
    if (!this.showDate) {
      this.showDate = true;
      this.showMonth = false;
      this.showPeriod = false;
      this.onCleanFilter();
    }
    this.filterType = 'date';
  }
  displayPeriod() {
    if (!this.showPeriod) {
      this.showDate = false;
      this.showMonth = false;
      this.showPeriod = true;
      this.onCleanFilter();
    }
    this.filterType = 'period';
  }
  displayMonth() {
    if (!this.showMonth) {
      this.showDate = false;
      this.showMonth = true;
      this.showPeriod = false;
      this.onCleanFilter();
    }
    this.filterType = 'month';
  }

  getFiltredPeriode(filter) {
    let beginDate = null;
    let endDate = null;
    const type = this.filterType;

    if (filter.date) { // filter on the day
      this.disableButton = false;
      beginDate = new Date(filter.date);
      if (this.filterWithLocalDate) {
        endDate = beginDate;
      } else {
        endDate = new Date(beginDate.getFullYear(), beginDate.getMonth(), beginDate.getDate() + 1);
      }
    }

    if (filter.period.beginDate && filter.period.endDate) {
      this.disableButton = false;
      beginDate =  new Date(filter.period.beginDate);
      endDate = new Date(filter.period.endDate);
      endDate.setHours(23, 59 , 59);
    }

    if (filter.month !== '') {
      this.disableButton = false;
      const selectedDate = this.filter.month.split(' ');
      beginDate = new Date(selectedDate[1], this.months.indexOf(selectedDate[0]), 1);
      endDate = this.lastDayOfTheMonth(beginDate);
      endDate.setHours(23, 59 , 59);
    }

    return { beginDate, endDate, type };
  }

}
