import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

/**
 * Form step component.
 *
 * @see StepsComponent
 */
@Component({
  selector: 'li[ap-step]',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepComponent implements OnInit {
  @Input() rel: number;
  @Input() value: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() activeStep: number;

  get isdone(): number { return this.activeStep >= this.rel ? 1 : 0 ; }

  get stepValue(): string { return !!this.value ? this.value : this.rel.toString(); }

  constructor() {}

  ngOnInit() {}

}
