import { Pipe, PipeTransform } from '@angular/core';
import { JourneyPortal } from '@app/core/models/journey/journey-portal';
import * as moment from 'moment';

@Pipe({
  name: 'hasJourneyEnded',
  pure: true
})
export class HasJourneyEnded implements PipeTransform {
  transform(journey: JourneyPortal): boolean {
    return moment(journey.startDate).isBefore(moment().startOf('day'));
  }
}
