/**
 * Mobility Engine API
 * Service that exposes REST API to access to Mobility Engine.
 *
 * OpenAPI spec version: 1.15.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Configuration } from '../configuration';
import { environment } from '@env/environment';
import { AccessMedia, AccessMediaHolderDTO } from '@app/core/models/models';
import { Page } from '@app/core/models/page';
import { map } from 'rxjs/operators';


@Injectable()
export class AccessMediaControllerService {

    protected basePath = '';
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {
      this.basePath = environment.passenger.api.mobilityEngine + '/api/access-control-manager';
    }

    /**
     * Delete an access media holder by his acces media id in the Access Control Manager
     *
     * @param accessMediaId Access media id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mDeleteAccessMediaHolder(accessMediaId: string, observe?: 'body', reportProgress?: boolean): Observable<Page<AccessMedia>> {

        if (!accessMediaId) {
            throw new Error('Required parameter term was null or undefined when calling mReadAccessMediasUsingGET.');
        }

        const headers = new HttpHeaders().set('Accept', 'application/json');
        const withCredentials = this.configuration.withCredentials;

        return this.httpClient.delete<any>(`${this.basePath}/v1/access-medias/${encodeURIComponent(String(accessMediaId))}/holder`,
            { withCredentials, headers, observe, reportProgress }
        );
    }

    /**
     * Read an access media by his type and reference in the Access Control Manager
     *
     * @param accessMediaReference Access media reference
     * @param accessMediaType Access media type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAccessMediaByTypeAndReferenceUsingGET(accessMediaReference: string, accessMediaType: string, observe?: 'body', reportProgress?: boolean): Observable<AccessMedia> {

        if (!accessMediaReference || !accessMediaType) {
            throw new Error('Required parameters accessMediaReference or accessMediaType were null or undefined when calling readAccessMediaByTypeAndReferenceUsingGET.');
        }

        const headers = new HttpHeaders().set('Accept', 'application/json');
        const withCredentials = this.configuration.withCredentials;

        return this.httpClient.get<AccessMedia>(`${this.basePath}/v1/types/${encodeURIComponent(accessMediaType)}/access-media?accessMediaReference=${accessMediaReference.replace("+", "%2B")}`,
          { withCredentials, headers, observe, reportProgress }
        );
    }

    /**
     * Does an access media have an holder
     *
     * @param accessMediaReference Access media reference
     * @param accessMediaType Access media type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public doesAccessMediaHaveHolderUsingGET(accessMediaReference: string, accessMediaType: string, observe?: 'body', reportProgress?: boolean): Observable<boolean> {

        if (!accessMediaReference || !accessMediaType) {
            throw new Error('Required parameters accessMediaReference or accessMediaType were null or undefined when calling doesAccessMediaHaveHolderUsingGET.');
        }

        const headers = new HttpHeaders().set('Accept', 'application/json');
        const withCredentials = this.configuration.withCredentials;

        return this.httpClient.get<boolean>(`${this.basePath}/v1/types/${encodeURIComponent(accessMediaType)}/access-media-holder?accessMediaReference=${accessMediaReference.replace("+", "%2B")}`,
          { withCredentials, headers, observe, reportProgress }
        );
    }


    /**
     * Read paginated access medias in the Access Control Manager
     *
     * @param mobilityAccountId Mobility account identifier
     * @param filter accessMediaReference and/or accessMediaStatuses for filtering
     * @param sortOrder Sort Order
     * @param sortElement Sort Element
     * @param pageNumber Page number to request
     * @param pageSize Size of the page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAccessMediasUsingGET(mobilityAccountId: number, filter?: { accessMediaReference?: string, accessMediaStatuses?: string[] }, sortOrder: 'DESC' | 'ASC' = 'ASC', sortElement: string = 'accessMediaCreationDate', pageNumber: number = 0, pageSize: number = 400, observe?: 'body', reportProgress?: boolean): Observable<Page<AccessMedia>> {

        if (!Number.isInteger(mobilityAccountId)) {
            throw new Error('Required parameter mobilityAccountId is null or undefined when calling readAccessMediasUsingGET.');
        }

        if (['accessMediaHolderEmailAddress', 'accessMediaHolderLastName', 'accessMediaHolderFirstName'].includes(sortElement)) {
            sortElement = 'accessMediaHolder.' + sortElement;
        }

        const sort = sortElement + ',' + sortOrder.toLowerCase();
        const params = new HttpParams()
            .set('sort', sort)
            .set('page', pageNumber.toString())
            .set('size', pageSize.toString());

        const headers = new HttpHeaders().set('Accept', 'application/json');
        const withCredentials = this.configuration.withCredentials;


        return this.httpClient.get<Page<AccessMedia>>(
            `${this.basePath}/v1/mobility-accounts/${encodeURIComponent(mobilityAccountId)}/access-medias`,
            { params, withCredentials, headers, observe, reportProgress }
        ).pipe(map(res => {
            if (filter && res && res.content) {
                res.content = res.content.filter(accessMediaResponse => {
                    let flag = true;
                    if (filter.accessMediaReference) {
                        flag = flag && filter.accessMediaReference === accessMediaResponse.accessMediaReference;
                    }
                    if (filter.accessMediaStatuses && filter.accessMediaStatuses.length > 0) {
                        flag = flag && filter.accessMediaStatuses.includes(accessMediaResponse.accessMediaStatus);
                    }
                    return flag;
                });
            }
            return res;
        }));
    }

    /**
     * Update access media holder in the Access Control Manager
     *
     * @param accessMediaName name to update
     * @param accessMediaId identifier of the access media to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAccessMediaNameUsingPATCH(accessMediaId: number, accessMediaName: string = '', observe?: 'body', reportProgress?: boolean): Observable<AccessMediaHolderDTO> {

        if (!Number.isInteger(accessMediaId)) {
            throw new Error('Required parameter accessMediaId is missing when calling updateAccessMediaHolderUsingPUT.');
        }

        const headers = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json');
        const withCredentials = this.configuration.withCredentials;

        return this.httpClient.patch<any>(`${this.basePath}/v1/access-medias/${encodeURIComponent(accessMediaId)}/name`,
            { accessMediaName },
            { withCredentials, headers, observe, reportProgress }
        );
    }

}
