import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { environment } from "@env/environment";
import { MaintenanceService } from "../services/maintenance.service";
import { MaintenanceConfigUI, MaintenanceStatus } from "../models/maintenance/maintenance-config.model";
import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class MaintenanceInterceptor implements HttpInterceptor {

  maintenanceConfig: MaintenanceConfigUI;

  constructor(
    private maintenanceService: MaintenanceService
  ){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.passenger.api.gateway) {
      return from(this.handleRequest(request, next));
    }
    return next.handle(request);
  }

  private async handleRequest(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const currentDate = moment(new Date());
    const browserUrl = window.location.href;

    // get local
    this.maintenanceConfig = this.maintenanceService.getMaintenanceConfigFromLocal();

    if (!this.maintenanceConfig || this.hasMaintenanceConfigExpired(currentDate) || this.hasMaintenanceActivated()) {
      // get new maintenanceConfig from BO
      if (!request.url.includes('maintenance') && this.maintenanceService.getMaintenanceStatus() === MaintenanceStatus.INITIALIZE) {
        this.maintenanceService.nextMaintenanceStatus(MaintenanceStatus.IN_PROGRESS);
        setTimeout(() => {
          this.maintenanceService.nextMaintenanceStatus(MaintenanceStatus.INITIALIZE);
        }, 10000);
        await this.maintenanceService.getMaintenanceConfig().toPromise().catch(() => {
          // if error, redirect to maintenance page
          this.maintenanceService.deleteMaintenanceConfigFromLocal();
          this.maintenanceService.nextMaintenanceActivedSubject(true);
          this.maintenanceService.nextShowBannerSubject(false);
          if (!browserUrl.includes('/maintenance') && this.maintenanceService.getMaintenanceStatus() !== MaintenanceStatus.IN_ERROR) {
            this.maintenanceService.nextMaintenanceStatus(MaintenanceStatus.IN_ERROR);
            window.location.href = '/maintenance';
          }
        });
      }

      // refresh local
      this.maintenanceConfig = this.maintenanceService.getMaintenanceConfigFromLocal();

      // if maintenance enabled go to maintenance page
      if (this.maintenanceConfig) {
        if (this.maintenanceConfig?.maintenance?.enabled) {
          this.maintenanceService.nextMaintenanceActivedSubject(true);
          this.maintenanceService.nextShowBannerSubject(false);
          if (!browserUrl.includes('/maintenance')) {
            window.location.href = '/maintenance';
          }
        } else {
          // maintenance not enabled, check if show banner and if we are not in maintenance page before handle request
          this.maintenanceService.nextMaintenanceActivedSubject(false);
          if (this.maintenanceConfig?.maintenance?.banner?.enabled) {
            // showBanner
            this.maintenanceService.nextShowBannerSubject(true);
          }

          if (browserUrl.includes('/maintenance')) {
            // redirect to home page
            window.location.href = '';
          }

        }
      }
    } else {
      if (this.maintenanceConfig.maintenance.banner.enabled) {
        // showBanner
        this.maintenanceService.nextShowBannerSubject(true);
      }
      if (browserUrl.includes('/maintenance')) {
        // redirect to home page
        window.location.href = '';
      }
    }

    return await next.handle(request).toPromise();
  }

  private hasMaintenanceConfigExpired(currentDate: moment.Moment) {
    let result = false;
    if (this.maintenanceConfig?.maintenance?.storage?.expiration?.delay) {
      const dateAfterDelay = moment(this.maintenanceConfig.creationDate).add(this.maintenanceConfig.maintenance.storage.expiration.delay).toDate();
      result = moment(dateAfterDelay).isBefore(currentDate);
    }
    return result;
  }

  private hasMaintenanceActivated() {
    return this.maintenanceConfig?.maintenance?.enabled || this.maintenanceConfig?.maintenance?.banner?.enabled;
  }
}
