import { Pipe, PipeTransform } from '@angular/core';
import { FeecappingRules } from '@app/core/models/journey/enum/tariff-rule.enum';
import { Tap } from '@app/core/models/journey/tap';

@Pipe({
  name: 'hasJourneyFeeCapingReach',
  pure: true
})
export class HasJourneyFeeCapingReach implements PipeTransform {
  feeCappingRules = FeecappingRules.feeCappingRules;

  transform(taps: Tap[]): string {
    let result = '';
    for(let tap of taps) {
      if (tap.fare && tap.fare.tariffRule) {
        if (this.feeCappingRules.includes(tap.fare.tariffRule.toString())) {
          if (tap.fare.passengersDetail) {
            if (tap.fare.passengersDetail.some(p => this.feeCappingRules.includes(p.tariffRule) && +p.amountMoney != 0)) {
              result = 'REACHED';
            }
            if (tap.fare.passengersDetail.some(p => this.feeCappingRules.includes(p.tariffRule) && +p.amountMoney == 0)) {
              return 'EXCEEDED';
            }
          } else if (+tap.fare.amountMoney !== 0) {
            result = 'REACHED';
          } else {
            return 'EXCEEDED';
          }
        } else if (tap.fare.tariffRule.toString() === 'MULTIPLE') {
          if (tap.fare.passengersDetail.some(p => this.feeCappingRules.includes(p.tariffRule) && +p.amountMoney != 0)) {
            result = 'REACHED';
          }
          if (tap.fare.passengersDetail.some(p => this.feeCappingRules.includes(p.tariffRule) && +p.amountMoney == 0)) {
            return 'EXCEEDED';
          }
        }
      }
    }
    return result;
  }
}
