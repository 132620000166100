import { Component, OnInit } from '@angular/core';
import { MaintenanceConfigUI } from '@app/core/models/maintenance/maintenance-config.model';
import { MaintenanceService } from '@app/core/services/maintenance.service';

@Component({
  selector: 'pp-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.css']
})
export class MaintenanceBannerComponent implements OnInit {

  fromDate: Date;
  toDate: Date;

  constructor(
    private maintenanceService: MaintenanceService
  ) {

  }

  ngOnInit(): void {
    this.maintenanceService.getMaintenanceConfig().subscribe((maintenanceConfig: MaintenanceConfigUI) => {
      this.fromDate = maintenanceConfig.maintenance.banner.from;
      this.toDate = maintenanceConfig.maintenance.banner.to;
    });
  }
}
