export enum TariffRuleEnum {
  FIXED = 'FIXED',
  CONNECTION = 'CONNECTION',
  TIMEFRAME = 'TIMEFRAME',
  TAP_IN = 'TAP_IN',
  TAP_OUT = 'TAP_OUT',
  PARTIAL_CAPPING = 'PARTIAL_CAPPING',
  ADJUSTMENT = 'ADJUSTMENT',
  FEE_CAPPING = 'FEE_CAPPING',
  PARTIAL_CAPPING_FECAPPING = 'PARTIAL_CAPPING_FECAPPING',
  ORIGIN_DESTINATION = 'ORIGIN_DESTINATION',
  MULTIPLE = 'MULTIPLE'
}

export namespace FeecappingRules {
  export const feeCappingRules = ['FEE_CAPPING', 'PARTIAL_CAPPING', 'PARTIAL_CAPPING_FECAPPING'];
}
